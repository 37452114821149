import { useRouter } from "next/router";
import { useEffect, useRef, useState, useContext } from "react";
import { isMobileAppHaveSavedToken, isInMobileApp, setCookie, getCookie, deleteAllCookies } from "../app/AppCommunication";
import AuthService from "../../services/auth.service";
import CountryCodes from "../../utils/countryCodes";
import SecurityAnimation from "./SecurityAnimation";
import Decrypt from "../../utils/encrypt";
import Captcha from "./Captcha";

import { GlobalContext } from "../../pages/globalState";

import lockBottomImage from "../../public/images/lockLoginBottom.svg";
import lockTopImage from "../../public/images/lockLoginTop.svg";
import shieldImage from "../../public/images/sheild.svg";

import LoginStyle from "../../styles/Login.module.scss";

function removePlus(value) {
  return !value ? value : value.replace("+", "");
}

function removeLeadingZero(value) {
  if (!value || value.toString().trim().length === 0) return null;

  let phoneNumber = value.match(/^0?(\d+)/);
  if (!phoneNumber || !phoneNumber[1]) return null;

  return phoneNumber[1];
}

const LoginForm = ({ setState, setSecurityPhase }) => {
  const router = useRouter();
  const InvalidDetails = `We couldn't find an account matching the following mobile number and birth year. Please try again or contact us at <a href="mailto:gti@gti-inv.com">gti@gti-inv.com</a>.`;
  const TempBlocked = `You have reached the login attempts limit. Please try again later or contact us at <a href="mailto:gti@gti-inv.com">gti@gti-inv.com</a>.`;
  const AuthServiceIssue = `We're experiencing technical issues at the moment. Please retry later or reach us at <a href="mailto:gti@gti-inv.com">gti@gti-inv.com</a>.`;
  const MemberLocked = `You have reached the login attempts limit. Please try again later or contact us at <a href="mailto:gti@gti-inv.com">gti@gti-inv.com</a> to reactivate your access.`;
  const SMSBlocked = "You have reached the SMS re-send limit, and the service is temporarily locked. Please try again later.";
  const InvalidCountryCode = "Please select a valid country code from the list.";

  const ccRef = useRef();
  const phoneRef = useRef();
  const yearRef = useRef();

  const [loginForm, setLoginForm] = useState({
    phoneNumber: "",
    yearOfBirth: "",
    countryCode: "",
  });

  const { t } = useContext(GlobalContext);

  const [visibleCountryCodes, setVisibleCountryCodes] = useState([]);
  const [displayCaptcha, setDisplayCaptcha] = useState(false);
  const [successCaptcha, setSuccessCaptcha] = useState(false);
  const [showLockLoader, setShowLockLoader] = useState(false);
  const [hadLoginError, setHadLoginError] = useState(null);
  const [countryCode, setCountryCodeValue] = useState([]);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [countryCodes, setCountryCodes] = useState([]);
  const [inMobileApp, setInMobileApp] = useState(false);
  const [authType, setAuthType] = useState("Face ID");
  const [shouldCheckIfInMobileApp, setShouldCheckIfInMobileApp] = useState(true);

  const MAX_TEMP_LOGIN_BLOCK = 5;

  useEffect(() => {
    setCountryCodes(CountryCodes);
    const getCountryCodeFromStorage = localStorage.getItem("countryCode");
    if (getCountryCodeFromStorage && !isNaN(getCountryCodeFromStorage)) setCountryCode(getCountryCodeFromStorage);
  }, []);

  useEffect(() => {
    if (loginAttempts === MAX_TEMP_LOGIN_BLOCK) {
      setHadLoginError(TempBlocked);
    }
  }, [loginAttempts]);

  const completedCaptcha = (isCallback) => {
    setTimeout(async () => {
      setCountryCodes(CountryCodes);
      const getCountryCodeFromStorage = localStorage.getItem("countryCode");
      if (getCountryCodeFromStorage && !isNaN(getCountryCodeFromStorage)) setCountryCode(getCountryCodeFromStorage);
      await loginWithPersonalDetails(isCallback);
    }, 100);
  };

  const solvedCaptcha = async () => {
    setDisplayCaptcha(false);
    setSuccessCaptcha(true);
    completedCaptcha(true);
  };

  const loginWithPersonalDetails = async (isCallback = false) => {
    if (loginAttempts === 2 && !successCaptcha && !isCallback) {
      setDisplayCaptcha(true);
      return false;
    }

    if (
      loginForm.phoneNumber &&
      loginForm.phoneNumber.toString().length > 6 &&
      loginForm.yearOfBirth &&
      loginForm.yearOfBirth.toString().length === 4
    ) {
      try {
        ccRef.current.blur();
        phoneRef.current.blur();
        yearRef.current.blur();

        const phoneNumber = countryCode + removeLeadingZero(loginForm.phoneNumber.toString());
        const yob = loginForm.yearOfBirth;

        const token = await AuthService.findUserByPhoneNumberAndYOB({
          phoneNumber,
          yob,
        });

        localStorage.setItem("lastActivity", new Date().getTime());
        localStorage.setItem("countryCode", countryCode.toString());

        setState((prevState) => {
          return {
            ...prevState,
            ...{
              phoneNumber: loginForm.phoneNumber,
              yearOfBirth: loginForm.yearOfBirth,
              loginAttempts: loginAttempts,
              countryCode: countryCode,
              otpToken: token.otpToken,
              email: token.email,
              wasOtpSent: true,
            },
          };
        });
      } catch (e) {
        setLoginAttempts(loginAttempts + 1);
        if (e.response.data.error === "SMS_BLOCKED") {
          setHadLoginError(SMSBlocked);
        } else if (e.response.data.error === "BLOCKED") {
          setHadLoginError(TempBlocked);
        } else if (e.response.data.error === "LOCKED") {
          setHadLoginError(MemberLocked);
        } else if (e.response.data.error === "AUTH_SERVICE_ERROR") {
          setHadLoginError(AuthServiceIssue);
        } else {
          setHadLoginError(InvalidDetails);
        }
        return false;
      }
    } else {
      setHadLoginError(InvalidDetails);
      return false;
    }
  };

  const verifyUser = async (event) => {
    event.preventDefault();

    const codeValue = document.getElementById("countryCode")?.value;
    setCountryCodeValue(codeValue);

    if (!countryCode || countryCode.toString().trim().length === 0) {
      setHadLoginError(InvalidCountryCode);
      return false;
    }

    const validateCountryCode = countryCodes.find((code) => removePlus(code.dial_code) === countryCode);

    if (!validateCountryCode) {
      setHadLoginError(InvalidCountryCode);
      return false;
    }

    let phoneNumber = removeLeadingZero(loginForm.phoneNumber);
    if (!phoneNumber) {
      setHadLoginError(InvalidCountryCode);
      return false;
    }

    await loginWithPersonalDetails();
  };

  const setCountryCode = (countryCode) => {
    try {
      const cleanCode = removePlus(countryCode);
      document.getElementById("countryCode").value = cleanCode;
      setCountryCodeValue(cleanCode);

      setTimeout(() => {
        if (document.getElementById("phoneNumber")) document.getElementById("phoneNumber").focus();
      }, 10);

      setVisibleCountryCodes([]);
    } catch (error) {}
  };

  const filterCodes = (value) => {
    return countryCodes
      .filter((code) => removePlus(code.dial_code).startsWith(removePlus(value)))
      .sort((a, b) => removePlus(a.dial_code) - removePlus(b.dial_code));
  };

  const showCountryCodesList = () => {
    const codeValue = document.getElementById("countryCode").value;
    if (codeValue.length === 0) {
      setVisibleCountryCodes(countryCodes.sort((a, b) => removePlus(a.dial_code) - removePlus(b.dial_code)));
    } else {
      setVisibleCountryCodes(filterCodes(codeValue));
    }
  };

  const updateCountryCode = (digits) => {
    const filteredCountryCodes = filterCodes(digits);

    if (filteredCountryCodes.length === 1 && removePlus(filteredCountryCodes[0].dial_code) === removePlus(digits)) {
      setTimeout(() => {
        document.getElementById("phoneNumber").focus();

        const cleanCountryCode = removePlus(digits);
        setCountryCodeValue(cleanCountryCode);
        updateForm({ countryCode: cleanCountryCode });
      }, 10);
      setVisibleCountryCodes([]);
    } else {
      setVisibleCountryCodes(filteredCountryCodes);
    }
  };

  const updateForm = (value) => {
    if (value.yearOfBirth && value.yearOfBirth.length > 4) {
      value.yearOfBirth = value.yearOfBirth.substring(0, 4);
    }

    setLoginForm((prevState) => {
      return { ...prevState, ...value };
    });
  };

  // Input are changing values on scroll. Disable this
  const disableInputScroll = (ref) => {
    if (ref) {
      try {
        const currentRef = ref.current;
        const wheelHandler = (e) => e.preventDefault();

        currentRef.addEventListener("wheel", wheelHandler);
        return () => currentRef.removeEventListener("wheel", wheelHandler);
      } catch (error) {}
    }
  };

  useEffect(() => {
    subscribeToPostMessageListener();

    isInMobileApp("LoginForm");

    return () => unsubscribeToPostMessageListener();
  }, []);

  const subscribeToPostMessageListener = () =>
    window.addEventListener("message", (ev) => {
      const { src } = ev.data;
      const srcIsMobileApp = src === "gti-mobile-app";
      if (srcIsMobileApp) {
        onMessageHandler(ev);
      }
    });

  const unsubscribeToPostMessageListener = () => window.removeEventListener("message", onMessageHandler);

  const onMessageHandler = (event) => {
    const { fn, data } = event.data;
    messageHandler(fn, data);
  };

  const messageHandler = (fn, data) => {
    switch (fn) {
      case "isInMobileApp":
        return isInMobileAppRes(data);
      case "isMobileAppHaveSavedToken":
        return isMobileAppHaveSavedTokenRes(data);
    }
  };

  const isInMobileAppRes = async (data) => {
    if (data.hasSavedToken) {
      isMobileAppHaveSavedToken();
      setInMobileApp(true);
      if (data?.platform === "android") {
        setAuthType("Touch ID");
      }
    }
  };

  const isMobileAppHaveSavedTokenRes = async (data) => {
    try {
      // deleteAllCookies();
      setCookie("token", data.token, 265);
      verifyOTP(data);
    } catch (error) {
      // window.alert("isMobileAppHaveSavedTokenRes", JSON.stringify(error));
    }
  };

  const verifyOTP = async (data) => {
    try {
      localStorage.setItem("keys", JSON.stringify(data.keys));
      let isValid = await setUsersMetaData(data);
      if (isValid) {
        setShowLockLoader(true);
      }
    } catch (error) {
      // window.alert(JSON.stringify(error));
      setHadLoginError(LoginFailed);
    }
  };

  const goToDashboard = () => {
    if (localStorage.getItem("activeAccount")) {
      localStorage.removeItem("activeAccount");
    }
    if (localStorage.getItem("activeAccountIndex")) {
      localStorage.removeItem("activeAccountIndex");
    }
    router.push("/dashboard");
  };

  const setUsersMetaData = async (data) => {
    const UserService = (await import("../../services/user.service")).default;
    const metaData = await UserService.getUserMetaData();

    // window.alert("metaData", JSON.stringify(metaData));

    if (Array.isArray(metaData)) {
      let decryptedAccounts = metaData
        .map((account) => Decrypt.decryptKey(account.accountId, account.encrypted))
        .filter((account) => account !== null);

      // window.alert(JSON.stringify("decryptedAccounts", decryptedAccounts));

      let userObject = decryptedAccounts[0];

      // window.alert("userObject", JSON.stringify(userObject));

      localStorage.setItem("user", JSON.stringify({ ...userObject, yearOfBirth: data.yob, phoneNumber: data.phoneNumber }));
    } else {
      localStorage.setItem("user", JSON.stringify({ ...metaData, yearOfBirth: data.yob, phoneNumber: data.phoneNumber }));
    }

    return true;
  };

  useEffect(() => disableInputScroll(ccRef), [ccRef]);
  useEffect(() => disableInputScroll(phoneRef), [phoneRef]);
  useEffect(() => disableInputScroll(yearRef), [yearRef]);

  return displayCaptcha ? (
    <Captcha
      setSecurityPhase={setSecurityPhase}
      phoneNumber={countryCode + removeLeadingZero(loginForm.phoneNumber.toString())}
      captchaResults={solvedCaptcha}
    />
  ) : showLockLoader ? (
    <SecurityAnimation
      shieldImage={shieldImage}
      lockTopImage={lockTopImage}
      lockBottomImage={lockBottomImage}
      animationCallback={goToDashboard}
    />
  ) : (
    <>
      <form
        onSubmit={(ev) => verifyUser(ev)}
        autoComplete="off"
        style={hadLoginError ? { marginTop: "0px" } : { display: "flex", flexDirection: "column" }}
      >
        <div id={LoginStyle.mobilePhoneField}>
          <label htmlFor="countryCode" id={LoginStyle.countryCodes}>
            <div id={LoginStyle.countryCodesDropdown}>
              {visibleCountryCodes.map((country, index) => (
                <div className={LoginStyle.countryCodeItem} key={`cc-${index}`} onClick={(ev) => setCountryCode(country.dial_code)}>
                  {country.dial_code} ({country.name})
                </div>
              ))}
            </div>
            <div style={{ position: "relative" }}>
              {ccRef.current && ccRef.current.value.trim().length > 0 && <div className={LoginStyle.countryPlus}>+</div>}
              <input
                onBlur={(event) => setTimeout(() => setVisibleCountryCodes([]), 300)}
                onChange={(event) => updateCountryCode(event.target.value)}
                onFocus={(event) => showCountryCodesList()}
                className={LoginStyle.countryCode}
                inputMode="numeric"
                autoComplete="off"
                id="countryCode"
                pattern="[0-9]*"
                type="number"
                ref={ccRef}
                step={0}
                min="0"
              />

              <div id={LoginStyle.dropdownIcon}></div>
            </div>
          </label>

          <label htmlFor="phoneNumber">
            <input
              style={{ direction: localStorage.getItem("language") === "he" ? "rtl" : "ltr" }}
              onChange={(event) => updateForm({ phoneNumber: event.target.value })}
              placeholder={t("phone-number")}
              value={loginForm.phoneNumber}
              inputMode="numeric"
              autoComplete="off"
              id="phoneNumber"
              pattern="[0-9]*"
              ref={phoneRef}
              type="number"
              step={0}
              min="0"
            />
          </label>
        </div>

        <label htmlFor="birthYear" style={{ marginBottom: "30px" }}>
          <input
            onChange={(event) => updateForm({ yearOfBirth: event.target.value })}
            placeholder={t("year-of-birth")}
            value={loginForm.yearOfBirth}
            inputMode="numeric"
            autoComplete="off"
            pattern="[0-9]*"
            id="birthYear"
            ref={yearRef}
            type="number"
            step={0}
            min="0"
          />
        </label>

        {hadLoginError !== null && <p id={LoginStyle.loginError} dangerouslySetInnerHTML={{ __html: hadLoginError }}></p>}

        <button id={LoginStyle.loginButton} type="submit" disabled={!loginForm.phoneNumber}>
          <img src="/images/lock.svg" />
          <span>{t("send-authentication-code")}</span>
        </button>
      </form>
      {inMobileApp && (
        <button
          style={{ borderRadius: 10 }}
          className="h-14 w-full mt-3 bg-[#387DD3] border-r-0 text-xl font-light"
          onClick={() => isMobileAppHaveSavedToken()}
        >
          Log in with {authType}
        </button>
      )}
      {/*
      {inMobileApp && (
        <button className="h-14 w-full mt-2 bg-red-500" onClick={() => generateAndSaveMobileAppToken()}>
          generateAndSaveMobileAppToken
        </button>
      )}
        {inMobileApp && (
          <button className="h-14 w-full mt-2 bg-red-500" onClick={() => disableFaceIdLogin()}>
            disableFaceIdLogin
          </button>
        )}
      */}
    </>
  );
};

export default LoginForm;
